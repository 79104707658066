const endpoints = {
  // setting API
  login: { url: "/login", method: "POST" },
  logout: { url: "/logout", method: "POST" },
  registration: { url: "/registration", method: "POST" },
  verifyOtp: { url: "/verify-otp", method: "POST" },
  resendOtp: { url: "/resend-otp", method: "POST" },
  forgotPassword: { url: "/forget-password", method: "POST" },
  resetPassword: { url: "/reset-password", method: "POST" },
  refreshToken: { url: "/refresh-token", method: "POST" },
  settings: { url: "/settings", method: "GET" },
  createRecipe: { url: "/recipe", method: "POST" },
  earningDetails: { url: "/earning-details", method: "POST" },
  demographicsReport: { url: "/demographics-report", method: "POST" },
  userRecipe: { url: "/get-user-recipe", method: "POST" },
  aiAssistance: { url: "/assistance", method: "POST" },
  countryList: { url: "/get-country", method: "POST" },
  fetchPinCode: { url: "/fetch-pincode-info", method: "POST" },
  sendAiAssistanceMessage: { url: "/send-message", method: "POST" },
  createRecipeHeader: { url: "/recipe-header", method: "POST" },
  updateRecipeHeader: { url: "/recipe-header", method: "PATCH" },
  submitForTesting: { url: "/submit-for-testing", method: "POST" },
  createRecipeIngredient: { url: "/recipe-item", method: "POST" },
  recipeTypeList: { url: "/get-recipe-type", method: "POST" },
  testerList: { url: "/get-testers", method: "POST" },
  recipeList: { url: "/get-recipe", method: "POST" },
  savedRecipeList: { url: "/get-user-saved-recipe", method: "POST" },
  updateRecipe: { url: "/recipe", method: "PATCH" },
  deleteRecipeHeader: { url: "/recipe-header", method: "DELETE" },
  deleteRecipeItems: { url: "/recipe-item", method: "DELETE" },
  reorderRecipeItems: { url: "/bulk-recipe", method: "PATCH" },
  detailRecipe: { url: "/recipe-details", method: "POST" },
  addComment: { url: "/comment", method: "POST" },
  addPublicProfile: { url: "/public-profile", method: "POST" },
  editPublicProfile: { url: "/public-profile", method: "PATCH" },
  getComment: { url: "/get-comments", method: "POST" },
  updateProfile: { url: "/update-profile", method: "POST" },
  getBanner: { url: "/get-banner-ad", method: "POST" },
  getProfile: { url: "/get-profile", method: "GET" },
  subscriptionPlanList: { url: "/get-subscription-plan", method: "POST" },
  createSubscription: { url: "/create-subscription", method: "POST" },
  cancelSubscription: { url: "/cancel-subscription", method: "GET" },
  publishRecipe: { url: "/publish-recipe", method: "POST" },
  getUserProfile: { url: "/user-profile", method: "POST" },
  testingRecipeList: { url: "/get-testing-recipe", method: "POST" },
  submitRecipeFeedback: { url: "/update-recipe-feedback", method: "POST" },
  createScrapedRecipe: { url: "/create-scraped-recipe", method: "POST" },
  getScrapedRecipe: { url: "/get-scrapped-recipe", method: "POST" },
  launchCaptureEmail: { url: "/capture-email", method: "POST" },
  dashboard: { url: "/dashboard", method: "POST" },
  socialLogin: { url: "/social-login", method: "POST" },
  support: { url: "/messages", method: "POST" },
  mostSavedRecipeList: { url: "/get-most-saved-recipe", method: "POST" },
  likeUnlikeRecipe: { url: "/like-or-unlike", method: "POST" },
  saveUnsaveRecipe: { url: "/save-or-remove", method: "POST" },
  duplicateRecipe: { url: "/duplicate-recipe", method: "POST" },
  feedback: { url: "/feedback", method: "POST" },
  performanceViewDetails: { url: "/get-view-performance", method: "POST" },
  performanceSaveDetails: { url: "/get-save-performance", method: "POST" },
  getConsumptionTrends: { url: "/get-consumption-trends", method: "POST" },
  performanceEngagementDetails: {
    url: "/get-user-engagement-performance",
    method: "POST",
  },
  performanceEngagementRecipeWise: {
    url: "/get-recipe-engagement-performance",
    method: "POST",
  },
  checkAccountAvailability: {
    url: "/check-availability",
    method: "POST",
  },
};
export default endpoints;
