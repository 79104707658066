import { AuthFooter } from "components/_common/Header/AuthFooter";
import AuthHeader from "components/_common/Header/AuthHeader";
import SideBar from "components/_common/Header/SideBar";
import React from "react";
import { Outlet } from "react-router-dom";
import ProtectedRoute from "routes/ProtectedRoute";
function AuthLayout() {
  return (
    <ProtectedRoute>
      <div className="position-sticky top-0 z-1 bg-white">
        <AuthHeader />
      </div>

      <section className="sidebar sidebar-scroll d-none d-md-block">
        <div className="sidebar-wrapper">
          <SideBar />
        </div>
      </section>

      <main className="app-wrapper">
        <Outlet />
      </main>
      <AuthFooter />
    </ProtectedRoute>
  );
}
export default AuthLayout;
