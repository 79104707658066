import { Preloader } from "components/_common/Preloader";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm, SubmitHandler, FieldValues, Resolver } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { errorMessage, successMessage } from "helpers/ToastNotificationHelper";
import { feedback } from "store/actions/RecipeAction";
import { blackCloseIcon } from "components/_common/Icons";

interface SendFeedbackModalProps {
  handleOnModalClose: () => void;
}

interface SendFeedbackFormValues {
  name: string;
  email: string;
  message: string;
  account: string;
  social_media_link: string;
}

function SendFeedbackModal(props: SendFeedbackModalProps) {
  const { handleOnModalClose } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(true);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(
      t("required_error_msg", { field_name: t("name") })
    ),
    email: Yup.string()
      .email()
      .required(t("required_error_msg", { field_name: t("email") })),
    account: Yup.string().required(
      t("required_error_msg", { field_name: t("account") })
    ),
    message: Yup.string().required(
      t("required_error_msg", { field_name: t("message") })
    ),
    social_media_link: Yup.string()
      .url()
      .required(
        t("required_error_msg", { field_name: t("social_media_link") })
      ),
  });

  const resolver: Resolver<SendFeedbackFormValues> =
    yupResolver(validationSchema);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SendFeedbackFormValues>({
    resolver,
  });

  const onSubmit: SubmitHandler<SendFeedbackFormValues> = async (data) => {
    const apiPayload = {
      type: "feedback",
      ...data,
    };
    if (!loading) {
      setLoading(true);
      feedback(apiPayload, (success: boolean, response: any) => {
        setLoading(false);
        if (success) {
            successMessage(t("feedback_send_successfully"));
          handleOnModalClose();
        } else {
          errorMessage(response.message);
        }
      });
    }
  };
  return (
    <Modal
      show={showModal}
      onHide={handleOnModalClose}
      id="addEditModal"
      scrollable={false}
      size="lg"
      backdrop={false}
      centered
    >
      <Preloader loading={loading} />
      <div className="card border-0 rounded-0">
        <div className="row m-0">
          <div className="col-12 col-sm-2 col-md-2 terms-service-visually-hidden bg-primary text-center px-4 align-content-center display-4"></div>
          <div className="col-12 col-sm-10 col-md-10">
            <div className="row bg-white text-black py-2">
              <div className="col-12 text-end">
                <span
                  aria-hidden="true"
                  onClick={() => handleOnModalClose()}
                  className="close cursor-pointer pe-3"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                   <img src={blackCloseIcon} width={"15px"} color="black"/>
                </span>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="col-12 p-0 m-0 px-4 pt-3">
                  <div className="form-group border-bottom border-black">
                    <h3 className="m-0 pb-2">Send Us Your Feedback!</h3>
                    <p>Updated September 2024</p>
                  </div>
                  <div className="form-group my-4 h-350 my-4 overflow-y-auto px-3">
                    <p>
                      Hi, I’m Luc, your AI assistant. Describe your issues or
                      question, and I’ll suggest the best you can do in this
                      section. Hi, I’m Luc, your AI assistant. Describe your
                      issues or question, and I’ll suggest the best you can do
                      in this section. Hi, I’m Luc, your AI assistant. Describe
                      your issues or question, and I’ll suggest the best you can
                      do in this section.
                    </p>

                    <div className="px-5 py-2">
                      <div className="input-group py-2 ">
                        <input
                          type="text"
                          className={`form-control bg-opacity-80 bg-white ${
                            errors.name ? "is-invalid" : ""
                          }`}
                          {...register("name")}
                          placeholder="Your name"
                        />
                        <div className="invalid-feedback error">
                          {errors.name?.message}
                        </div>
                      </div>
                      <div className="input-group py-2 ">
                        <input
                          type="email"
                          className={`form-control bg-opacity-80 bg-white ${
                            errors.email ? "is-invalid" : ""
                          }`}
                          {...register("email")}
                          placeholder="Email"
                        />
                        <div className="invalid-feedback error">
                          {errors.email?.message}
                        </div>
                      </div>
                      <div className="input-group py-2 ">
                        <input
                          type="text"
                          className={`form-control bg-opacity-80 bg-white ${
                            errors.account ? "is-invalid" : ""
                          }`}
                          {...register("account")}
                          placeholder="Your account"
                        />
                        <div className="invalid-feedback error">
                          {errors.account?.message}
                        </div>
                      </div>
                      <div className="input-group py-2 ">
                        <input
                          type="text"
                          className={`form-control bg-opacity-80 bg-white ${
                            errors.social_media_link ? "is-invalid" : ""
                          }`}
                          {...register("social_media_link")}
                          placeholder="Social Media Link"
                        />
                        <div className="invalid-feedback error">
                          {errors.social_media_link?.message}
                        </div>
                      </div>
                      <div className="form-group py-2 ">
                        <textarea
                          className={`form-control bg-opacity-80 bg-white ${
                            errors.message ? "is-invalid" : ""
                          }`}
                          {...register("message")}
                          placeholder="Message"
                        ></textarea>
                        <div className="invalid-feedback error">
                          {errors.message?.message}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 my-4">
                  <div className="form-group text-end">
                    <button
                      type="submit"
                      className="btn-create-primary px-3 py-1 w-auto rounded-0 me-4"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default SendFeedbackModal;
