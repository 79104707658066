import {
  closeIcon,
  PlaceholderIcon,
  PopupIcon,
} from "components/_common/Icons";
import { t } from "i18next";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm, SubmitHandler, FieldValues } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import { mainRoutes as routes } from "config/routing";
import { Preloader } from "components/_common/Preloader";
import { errorMessage, successMessage } from "helpers/ToastNotificationHelper";
import {
  addPublicProfile,
  editPublicProfile,
  handleProfileResponseChange,
  updateProfile,
} from "store/actions/AuthAction";
import {
  getAzureFilePublicUrl,
  uploadBase64ToAzure,
  uploadFileToAzure,
} from "helpers/AzureHelper";
import { log } from "util";
import { useTranslation } from "react-i18next";

interface PublicProfileModalProps {
  showModal: boolean;
  existingDetails: any;
  handleOnModalClose: () => void;
  callbackFun?: any;
}
interface PublicProfileValues {
  first_name?: string;
  tagline?: string;
  email?: string;
  profile_pic?: string;
  profile_file_name: any;
  facebook_link?: string;
  instagram_link?: string;
  pinterest_link?: string;
  website_link?: string;
  youtube_link?: string;
  x_link?: string;
}

export default function PublicProfileModal(props: PublicProfileModalProps) {
  const [imageValidate, setImageValidate] = useState<any>({
    selectedFile: PlaceholderIcon, // to store selected file
    handleResponse: null, // handle the API response
    imageUrl: null, // to store uploaded image path
    invalidImage: null, // handle the message of the image validation
  });
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { handleOnModalClose, showModal, existingDetails, callbackFun } = props;
  const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object().shape({
    display_name: Yup.string().required(
      t("required_error_msg", { field_name: t("first_name") })
    ),
    tagline: Yup.string().nullable(),
    email: Yup.string().nullable(),
    facebook_link: Yup.string().nullable(),
    instagram_link: Yup.string().nullable(),
    website_link: Yup.string().nullable(),
    pinterest_link: Yup.string().nullable(),
    youtube_link: Yup.string().nullable(),
    x_link: Yup.string().nullable(),
    profile_file_name: Yup.mixed().nullable(),
    profile_pic: Yup.string().nullable(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  useEffect(() => {
    // existing values
    if (existingDetails && Object.keys(existingDetails).length > 0) {
      if (existingDetails) {
        if (existingDetails.user.profile_pic) {
          setValue(
            "profile_pic",
            existingDetails.user.profile_pic
              ? existingDetails.user.profile_pic
              : ""
          );
          setImageValidate({
            ...imageValidate,
            ...{
              selectedFile: getAzureFilePublicUrl(
                existingDetails.user.profile_pic
              ),
            },
          });
        }
        if (existingDetails.user.userPublicProfile) {
          setValue(
            "display_name",
            existingDetails.user.userPublicProfile.display_name
          );
          setValue("tagline", existingDetails.user.userPublicProfile.tagline);
          setValue("email", existingDetails.user.email);
          setValue(
            "facebook_link",
            existingDetails.user.userPublicProfile.facebook_link
          );
          setValue(
            "website_link",
            existingDetails.user.userPublicProfile.facebook_link
          );
          setValue(
            "instagram_link",
            existingDetails.user.userPublicProfile.instagram_link
          );

          setValue(
            "pinterest_link",
            existingDetails.user.userPublicProfile.pinterest_link
          );
          setValue("x_link", existingDetails.user.userPublicProfile.x_link);
          setValue(
            "youtube_link",
            existingDetails.user.userPublicProfile.youtube_link
          );
        }
      }
    }
  }, [existingDetails]);
  console.log(existingDetails, "existingDetails");

  const onSubmit: SubmitHandler<any> = async (data) => {
    console.log("data", data);

    const apiPayload = {
      // profile_pic: uploadImageFromURL(data.profile_pic), // Corrected the syntax here
      ...data,
    };

    if (!loading) {
      setLoading(true);
      if (
        apiPayload.profile_file_name &&
        apiPayload.profile_file_name.length > 0
      ) {
        const uploadResponse: any = await uploadFileToAzure(
          "profile",
          apiPayload.profile_file_name[0]
        );
        if (uploadResponse.status) {
          // If upload is successful,
          apiPayload.profile_pic = uploadResponse.fileName;
        } else {
          errorMessage(uploadResponse.message);
          return false;
        }
      }
      delete apiPayload.profile_file_name;
      console.log(existingDetails.user.userPublicProfile);

      if (existingDetails.user.userPublicProfile) {
        // edit
        editPublicProfile(apiPayload, (success: boolean, response: any) => {
          setLoading(false);
          if (success) {
            successMessage(t("edit_successfully"));
            if (response.data) {
              handleProfileResponseChange(response.data);
            }
            if (callbackFun) {
              callbackFun();
            } else {
              handleOnModalClose();
            }
          } else {
            errorMessage(response.message);
          }
        });
      } else {
        // add
        addPublicProfile(apiPayload, (success: boolean, response: any) => {
          setLoading(false);
          if (success) {
            successMessage(t("add_successfully"));
            if (response.data) {
              handleProfileResponseChange(response.data);
            }
            if (callbackFun) {
              callbackFun();
            } else {
              handleOnModalClose();
            }
          } else {
            errorMessage(response.message);
          }
        });
      }
    }
  };
  const onChangeFile = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const imageFile = event.target.files[0];
      if (!imageFile) {
        return false;
      }

      if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
        setImageValidate({
          ...imageValidate,
          ...{
            invalidImage: t("valid_image_error_msg"),
            selectedFile: PlaceholderIcon,
          },
        });
        setValue("profile_file_name", "");
        return false;
      } else {
        setImageValidate({
          ...imageValidate,
          ...{
            selectedFile: URL.createObjectURL(imageFile),
            invalidImage: null,
          },
        });
        // setValue('profile_file_name', imageFile);
        // setImageArray([...imageArray, { [ele]: imageFile }]);
        clearErrors("profile_pic");
      }
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={handleOnModalClose}
      id="addEditModal"
      scrollable={false}
      size="lg"
      backdrop={false}
      centered
    >
      <Preloader loading={loading} />
      <div className="recipe-modal">
        <Modal.Header className="p-0 px-2 py-1">
          <div className="col-1">
            <img src={PopupIcon} style={{ width: "60%", minWidth: "80%" }} />
          </div>
          <div className="col-9 p-0 m-0">
            <h6>{t("public_profile")}</h6>
          </div>
          <div className="col-2 text-end">
            <span
              aria-hidden="true"
              onClick={handleOnModalClose}
              className="close cursor-pointer cursor-pointer px-3"
              data-dismiss="modal"
              aria-label="Close"
            >
              <img style={{ width: "15px" }} src={closeIcon} alt="close" />
            </span>
          </div>
        </Modal.Header>
        <Modal.Body className="bg-secondary-subtle h-75dvh overflow-y-scroll">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div className="row m-0">
                <div className="col-12">
                  <p>
                    The information on this page will be visible to other users.
                    If you want this information to be private, select the
                    option to keep a private profile.
                  </p>
                </div>
              </div>
              <div className="row m-0">
                <h5 className="border-bottom border-black py-2">About Me</h5>
                <div className="formgroup row m-0">
                  <div className="col-12 col-md-6">
                    <div className="col-12 my-2">
                      <div className="form-group">
                        <label>Display Name</label>
                        <input
                          type="text"
                          className={`form-control ${
                            errors.display_name ? "is-invalid" : ""
                          }`}
                          {...register("display_name")}
                          placeholder="First Name"
                        />
                        <div className="invalid-feedback error">
                          {errors.display_name?.message}
                        </div>
                      </div>
                    </div>

                    <div className="col-12 my-2">
                      <div className="form-group">
                        <label>Tagline</label>
                        <textarea
                          className="form-control"
                          {...register("tagline")}
                          id="exampleFormControlTextarea1"
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-6 my-2">
                    <div className="form-group px-md-5">
                      <label>Add an Image</label>
                      <div className="image-preview" id="imagePreview">
                        {/* <p>Profile Photo</p> */}
                        <img src={imageValidate.selectedFile} />
                      </div>
                      <input
                        type="file"
                        id="imageUpload"
                        {...register("profile_file_name")}
                        accept="image/*"
                        onInput={onChangeFile}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row m-0">
                <h5 className="border-bottom border-black py-2">
                  Social Media Accounts
                </h5>
                <div className="col-12 my-2">
                  <div className="form-group">
                    <label>Email address</label>
                    <input
                      type="email"
                      className="form-control"
                      {...register("email")}
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter email"
                    />
                  </div>
                </div>

                <div className="col-12 col-md-6 my-2">
                  <div className="form-group">
                    <label>Facebook</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("facebook_link")}
                      placeholder="Add Facebook Id..."
                    />
                  </div>
                </div>

                <div className="col-12 col-md-6 my-2">
                  <div className="form-group">
                    <label>Instagram</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("instagram_link")}
                      placeholder="Add Instagram Id..."
                    />
                  </div>
                </div>

                <div className="col-12 col-md-6 my-2">
                  <div className="form-group">
                    <label>Website</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("website_link")}
                      placeholder="Add Website..."
                    />
                  </div>
                </div>

                <div className="col-12 col-md-6 my-2">
                  <div className="form-group">
                    <label>Pinterest</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("pinterest_link")}
                      placeholder="Add Pinterest Id..."
                    />
                  </div>
                </div>

                <div className="col-12 col-md-6 my-2">
                  <div className="form-group">
                    <label>YouTube</label>
                    <input
                      type="text"
                      {...register("youtube_link")}
                      className="form-control"
                      placeholder="Add YouTube Channel..."
                    />
                  </div>
                </div>

                <div className="col-12 col-md-6 my-2">
                  <div className="form-group">
                    <label>X (Twitter)</label>
                    <input
                      type="text"
                      {...register("x_link")}
                      className="form-control"
                      placeholder="Add X (Twitter) Id..."
                    />
                  </div>
                </div>

                <div className="col-12 my-2 text-end">
                  <button
                    type="submit"
                    className="btn-create-primary px-3 w-auto my-1 py-2"
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </div>
    </Modal>
  );
}
