import React, { useState } from "react";
import { AvatarImg, Like, TopbarSearchIcon } from "../Icons";
import NavbarLinks from "./NavBarLinks";
import { getUserInfo } from "helpers/StorageHelper";
import SendFeedbackModal from "components/modules/Recipes/RecipeDetail/Modals/SendFeedbackModal";
import { getAzureFilePublicUrl } from "helpers/AzureHelper";
import { capitalizeWord } from "helpers/CommonHelper";
import { userProfileSelector } from "components/selectors/authSelectors";
import { RootState } from "store";
import { connect } from "react-redux";

function SideBar(props: { userProfileInfo: any }) {
  // const userInfo = getUserInfo();
  const { userProfileInfo } = props;
  console.log("userInfo", userProfileInfo);
  const [isOpenSendFeedbackModal, setIsOpenSendFeedbackModal] = useState(false);
  const onOpenSendFeedbackModal = () => {
    setIsOpenSendFeedbackModal(true);
  };
  const handleOnModalClose = () => {
    setIsOpenSendFeedbackModal(false);
  };

  return (
    <>
      {isOpenSendFeedbackModal && (
        <SendFeedbackModal handleOnModalClose={handleOnModalClose} />
      )}
      <div className="border border-1 border-start-0 border-top-0 border-bottom-0 flex-nowrap">
        {userProfileInfo && Object.keys(userProfileInfo).length > 0 && (
          <div className="align-items-center text-center bg-white py-2">
            <a href="#" className="text-decoration-none" aria-expanded="false">
              <img
                src={
                  userProfileInfo.profile_pic
                    ? getAzureFilePublicUrl(userProfileInfo.profile_pic)
                    : AvatarImg
                }
                // style={{ maxWidth: "60px" }}
                className="menu-profile-image"
                // className="img-fluid"
              />
            </a>
            <div className="user-name-box pt-4">
              {capitalizeWord(
                userProfileInfo.first_name + " " + userProfileInfo.last_name
              )}
            </div>
          </div>
        )}
        <div className="px-3 pt-2 ">
          <NavbarLinks />
          <hr />
          <div className="pb-4 bottom-0 pb-4 position-fixed bg-white mobile-only-footer d-none d-md-block">
            <ul className="nav nav-pills" id="menu">
              {/* <li className="nav-item">
                  <a href="#" className="nav-link align-middle px-0">
                    <img
                      src={TopbarSearchIcon}
                      className="shadow-sm border"
                      width="20"
                      height="20"
                    />
                    <span className="px-2 d-none d-sm-inline">Settings</span>
                  </a>
                </li> */}
              <li className="nav-item">
                <div className="d-flex align-items-center">
                  <img src={Like} width="20" height="20" />
                  <a
                    href="#"
                    className="nav-link"
                    onClick={() => onOpenSendFeedbackModal()}
                  >
                    Send Feedback
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state: RootState) => {
  return {
    userProfileInfo: userProfileSelector(state),
  };
};
export default connect(mapStateToProps, {})(SideBar);
