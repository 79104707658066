import { AvatarImg } from "components/_common/Icons";
import { userProfileSelector } from "components/selectors/authSelectors";
import { loginRoutes } from "config/routing";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { RootState } from "store";
import { getProfile, logoutUser } from "store/actions/AuthAction";
import PersonalProfileModal from "./Modals/PersonalProfileModal";
import PublicProfileModal from "./Modals/PublicProfileModal";
import { errorMessage } from "helpers/ToastNotificationHelper";
import { getUserInfo } from "helpers/StorageHelper";
import { mainRoutes as routes } from "config/routing";
import { getAzureFilePublicUrl } from "helpers/AzureHelper";

const ProfileDropdownMenu = ({ userProfileInfo }: { userProfileInfo: any }) => {
  const userInfo = getUserInfo();
  const [loading, setLoading] = useState(false);
  const [existingDetails, setExistingDetails] = useState({});
  const [showPersonalProfileModal, setShowPersonalProfileModal] =
    useState(false);
  const [showPublicProfileModal, setShowPublicProfileModal] = useState(false);
  const navigate = useNavigate();
  // personal
  const openPersonalProfileModal = () => {
    setShowPersonalProfileModal(true);
  };
  const handleClosePersonalProfileModal = () => {
    setShowPersonalProfileModal(false);
  };
  // public
  const openPublicProfileModal = () => {
    setShowPublicProfileModal(true);
  };
  const handleClosePublicProfileModal = () => {
    setShowPublicProfileModal(false);
  };
  const confirmLogout = () => {
    if (!loading) {
      // confirmation
      // confirmAlert(t("confirm_logout_msg"), () => {
      // confirmed yes

      setLoading(true);
      logoutUser(() => {
        setLoading(false);
        navigate(loginRoutes.login.path);
      });

      // });
    }
  };
  useEffect(() => {
    callDetailsAPI();
  }, []);
  const callDetailsAPI = () => {
    const params = {};
    if (!loading) {
      setLoading(true);
      getProfile(params, (success: boolean, response: any) => {
        setLoading(false);
        if (success) {
          setExistingDetails(response.data);
        } else {
          errorMessage(response.message);
        }
      });
    }
  };
  if (userProfileInfo && Object.keys(userProfileInfo).length > 0) {
    return (
      <>
        <div className="dropdown-popup end-0 float-end">
          <button className="dropbtn bg-white">
            <img
              src={
                userProfileInfo.profile_pic
                  ? getAzureFilePublicUrl(userProfileInfo.profile_pic)
                  : AvatarImg
              }
              /* src={AvatarImg} */
              style={{ maxWidth: "60px" }}
              className="mx-2 profile-image"
            />
          </button>

          <div className="dropdown-content end-0 px-2">
            <div className="row m-0 py-3 border-bottom border-black">
              <div className="col-4">
                <img
                  src={
                    userProfileInfo.profile_pic
                      ? getAzureFilePublicUrl(userProfileInfo.profile_pic)
                      : AvatarImg
                  }
                  style={{ maxWidth: "60px" }}
                  className="profile-image"
                  /* src={AvatarImg} */
                />
              </div>
              <div className="col-8">
                <h5>
                  {userProfileInfo.first_name + " " + userProfileInfo.last_name}
                </h5>
                <p>{userProfileInfo.email}</p>
              </div>
            </div>

            <div className="row m-0 py-3 border-bottom border-black">
              <div className="col-12">
                <ul className="list-group">
                  <li
                    className="list-group-item fw-medium cursor-pointer"
                    aria-current="true"
                    onClick={() => openPersonalProfileModal()}
                  >
                    Personal Info
                  </li>
                  <li
                    className="list-group-item fw-medium cursor-pointer"
                    onClick={() => openPublicProfileModal()}
                  >
                    Public Profile
                  </li>
                </ul>
              </div>
            </div>

            <div className="row m-0 py-3 border-black">
              <div className="col-12">
                <ul className="nav signup_popup">
                  <li className="nav-item">
                    <Link to={routes.savedRecipePage.path}>
                      Saved Recipes & Collections
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to={routes.recipeVault.path}>My Recipe Box</Link>
                  </li>
                </ul>
              </div>
              <div className="col-12 text-end">
                <button
                  type="submit"
                  className="btn-create-primary px-2 w-auto my-1 py-1"
                  onClick={() => confirmLogout()}
                >
                  {t("signout")}
                </button>
              </div>
            </div>
          </div>
        </div>
        {showPersonalProfileModal && (
          <PersonalProfileModal
            showModal={showPersonalProfileModal}
            existingDetails={existingDetails}
            handleOnModalClose={handleClosePersonalProfileModal}
          />
        )}
        {showPublicProfileModal && userProfileInfo && (
          <PublicProfileModal
            showModal={showPublicProfileModal}
            existingDetails={{ user: userProfileInfo }}
            handleOnModalClose={handleClosePublicProfileModal}
          />
        )}

        {/* <img width={61} height={61} src={AvatarImg} className="mx-2" />
      <span className="fw-bold">
        <a onClick={() => confirmLogout()} className="cursor-pointer">
          {t("signout")}
        </a>
      </span>
      {showPersonalProfileModal && (
        <PersonalProfileModal
          showModal={showPersonalProfileModal}
          handleOnModalClose={handleClosePersonalProfileModal}
        />
      )}
      {showPublicProfileModal && (
        <PublicProfileModal
          showModal={showPublicProfileModal}
          handleOnModalClose={handleClosePublicProfileModal}
        />
      )} */}
      </>
    );
  } else {
    return null;
  }
};
const mapStateToProps = (state: RootState) => {
  return {
    userProfileInfo: userProfileSelector(state),
  };
};
export default connect(mapStateToProps, {})(ProfileDropdownMenu);
