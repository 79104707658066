import React, { useState } from "react";
import {
  AppBetaLogo,
  AppLogo,
  AvatarImg,
  blackCloseIcon,
  closeIcon,
  SearchIcon,
  TopbarSearchIcon,
} from "../Icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  homePageRoutes,
  loginRoutes,
  mainRoutes as routes,
} from "config/routing";
import { getUserInfo } from "helpers/StorageHelper";
import { log } from "console";
import { useTranslation } from "react-i18next";
import {
  handleRecipeDetailActionMsg,
  logoutUser,
} from "store/actions/AuthAction";
import { RootState } from "store";
import { connect } from "react-redux";
import {
  hasUserSubScribedSelector,
  userProfileSelector,
} from "components/selectors/authSelectors";
import ProfileDropdownMenu from "./HeaderMenu/ProfileDropdownMenu";
import { checkActiveRoute } from "helpers/CommonHelper";
import { Preloader } from "../Preloader";

const Header = ({
  userProfileInfo,
  hasUserSubScribed,
  showHeaderMenu,
}: {
  userProfileInfo: any;
  hasUserSubScribed: any;
  showHeaderMenu: boolean;
}) => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [close, setClose] = useState(false);
  const [showHeaderNavMenu, setShowHeaderNavMenu] = useState(showHeaderMenu);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const userInfo = getUserInfo();
  const pathname: string = location.pathname;
  const isHomePage = pathname === homePageRoutes.homePage.path;
  const isSignUpPage = pathname === loginRoutes.signUp.path;
  const isLoginPage = pathname === loginRoutes.login.path;
  const isVerifyOtpPage = pathname === loginRoutes.verifyOtp.path;
  const isSubscriptionPlanPage = pathname === loginRoutes.subscriptionPlan.path;
  const isResetPasswordPage = pathname === loginRoutes.resetPassword.path;
  const isForgotPasswordPage = pathname === loginRoutes.forgotPassword.path;
  const onCreateRecipe = () => {
    navigate(routes.dashboard.path);
  };
  const onMyRecipeBox = () => {
    navigate(routes.recipeVault.path);
  };
  const confirmLogout = () => {
    if (!loading) {
      // confirmation
      // confirmAlert(t("confirm_logout_msg"), () => {
      // confirmed yes
      setLoading(true);
      logoutUser(() => {
        setLoading(false);
        navigate(loginRoutes.login.path);
      });
      // });
    }
  };
  const getAppLogoContent = () => {
    const imgTag = (
      <img src={AppBetaLogo} alt="App Logo" className="public-brand-logo" />
    );
    if (isHomePage) {
      /* return <img src={AppLogo} alt="App Logo" />; */
      return imgTag;
    } else {
      return (
        <Link to={homePageRoutes.homePage.path}>
          {/* <img src={AppLogo} alt="App Logo" /> */}
          {imgTag}
        </Link>
      );
    }
  };
  const handleSearch = () => {
    setClose(true);
    navigate(homePageRoutes.CommunityPage.path, {
      state: { searchTerm, close },
    });
  };
  const handleClose = () => {
    setClose(false);
    setSearchTerm("");
    navigate(homePageRoutes.CommunityPage.path, {
      state: { searchTerm: "", close },
    });
  };
  return (
    <>
      <Preloader loading={loading} />
      <div className="position-sticky top-0 z-2 w-100 bg-white">
        {isSignUpPage ||
        isLoginPage ||
        isVerifyOtpPage ||
        isResetPasswordPage ||
        isForgotPasswordPage ||
        isSubscriptionPlanPage ? (
          <div className="container-fluid topbar bg-white">
            <div className="row">
              <div className="col-6 col-md-3 py-2">{getAppLogoContent()}</div>
              <div className="col-6 col-md-9 py-2 align-content-around text-end">
                {userProfileInfo ? (
                  <>
                    <ProfileDropdownMenu />
                  </>
                ) : (
                  <>
                    {isLoginPage && (
                      <Link
                        className="btn-signup-login"
                        to={loginRoutes.signUp.path}
                      >
                        {t("sign_up")}
                      </Link>
                    )}
                    {isSignUpPage && (
                      <Link
                        className="btn-signup-login"
                        to={loginRoutes.login.path}
                      >
                        {t("login")}
                      </Link>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="container-fluid topbar bg-white">
              <div className="row">
                <div className="col-10 col-md-4 py-2">
                  {getAppLogoContent()}
                </div>

                <div className="col-12 col-md-6 py-2 align-content-around d-none d-md-block">
                  {/* <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Find a recipe or ingredient"
                      aria-label="Find a recipe or ingredient"
                    />
                    <button
                      className="btn btn-outline-secondary p-0 border-0"
                      type="button"
                      id="button-addon2"
                    >
                      <img src={TopbarSearchIcon} alt="Search" />
                    </button>
                  </div> */}
                  <div className="input-group w-md-85">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Find a recipe or ingredient"
                      aria-label="Find a recipe or ingredient"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    {close ? (
                      <div
                        className="input-group-append"
                        onClick={() => handleClose()}
                      >
                        <span
                          className="input-group-text px-3 py-2 rounded-0 rounded-end-2"
                          id="basic-addon2"
                        >
                          <img
                            style={{ width: "20px" }}
                            src={blackCloseIcon}
                            alt="Search"
                          />
                        </span>
                      </div>
                    ) : (
                      <div
                        className="input-group-append"
                        onClick={() => handleSearch()}
                      >
                        <span
                          className="input-group-text px-3 py-2 rounded-0 rounded-end-2"
                          id="basic-addon2"
                        >
                          <img
                            style={{ width: "20px" }}
                            src={SearchIcon}
                            alt="Search"
                          />
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-2 col-md-2 py-2 align-content-around text-end">
                  {!userInfo ? (
                    <>
                      <Link
                        className="btn-signup-login"
                        to={loginRoutes.signUp.path}
                      >
                        {t("sign_up")}
                      </Link>
                      <Link
                        className="btn-signup-login"
                        to={loginRoutes.login.path}
                      >
                        {t("login")}
                      </Link>
                    </>
                  ) : (
                    <>
                      <ProfileDropdownMenu />
                    </>
                  )}
                </div>
              </div>
            </div>
            {/* Conditionally render the navbar based on the current path */}
            {showHeaderNavMenu && (
              <nav className="navbar navbar-expand-lg navbar-light bg-white">
                <div className="container-fluid d-block">
                  <div className="row">
                    <div className="col-12 col-md-8 gx-0">
                      <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav navbar-header-menu">
                          <li className="nav-item">
                            <Link
                              className={`nav-link ${checkActiveRoute(
                                pathname,
                                [homePageRoutes.homePage.path],
                                false,
                                true
                              )}`}
                              aria-current="page"
                              to={homePageRoutes.homePage.path}
                            >
                              {t("let_cook")}
                            </Link>
                          </li>
                          {/* <li className="nav-item">
                          <a className="nav-link" href="#">
                            {t("empty_for_now")}
                          </a>
                        </li> */}
                          {/* <li className="nav-item">
                          <a className="nav-link" href="#">
                            {t("recipes")}
                          </a>
                        </li> */}
                          <li className="nav-item">
                            <Link
                              className={`nav-link ${checkActiveRoute(
                                pathname,
                                [homePageRoutes.CommunityPage.path],
                                false
                              )}`}
                              to={homePageRoutes.CommunityPage.path}
                            >
                              {t("community")}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {userInfo && (
                      <div className="col-12 col-md-4 text-end">
                        <button
                          onClick={() => onCreateRecipe()}
                          type="button"
                          className="btn-create-primary px-3 w-auto mx-2 py-2"
                        >
                          Create Recipe
                        </button>
                        <button
                          onClick={() => onMyRecipeBox()}
                          type="button"
                          className="btn-create-primary px-3 w-auto mx-2 py-2"
                        >
                          My Recipe Box
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </nav>
            )}
          </>
        )}
      </div>
    </>
  );
};
const mapStateToProps = (state: RootState) => {
  return {
    userProfileInfo: userProfileSelector(state),
    hasUserSubScribed: hasUserSubScribedSelector(state),
  };
};
export default connect(mapStateToProps, {})(Header);
